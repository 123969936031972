.toggle-slider {
  position: relative;
  width: 30px;
  height: 17px;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: gray;
  border-radius: 17px;
  cursor: pointer;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked + .slider {
  background-color: gray;
}

input[type="checkbox"]:checked + .slider:before {
  transform: translateX(13px);
}
